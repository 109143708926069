import type {ReactElement} from 'react';

import CircularProgress from '@mui/material/CircularProgress/CircularProgress.js';

import type JournalModel from '@refinio/one.models/lib/models/JournalModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import Journal from '@refinio/one.ui/lib/ui/views/journal/Journal.js';
import type ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';

import {useEventTypes} from '@/hooks/journal/hooks.js';
import {ShareJournalEntryPopUp} from '@/components/shareJournalEntryPopup/ShareJournalEntryPopUp.js';
import {useShareJournalEntryDefaults} from '@/components/shareJournalEntryPopup/useShareJournalEntryDefaults.js';

import './JournalView.css';

/**
 * @param props.channelManager needed for sharing output
 * @param props.questionnaireModel needed to get names of questionnaires
 * @param props.journalModel
 * @param props.leuteModel needed for sharing list
 * @param props.topicModel needed for sharing ouput
 * @returns
 */
export default function JournalView(props: {
    channelManager: ChannelManager;
    questionnaireModel: QuestionnaireModel;
    journalModel: JournalModel;
    leuteModel: LeuteModel;
    topicModel: TopicModel;
}): ReactElement {
    const eventTypes = useEventTypes(props.questionnaireModel);
    const {sharedEntry, isSharePopupOpen, addonRenderFn, close} = useShareJournalEntryDefaults();

    // eventTypes names are required for UI
    if (eventTypes === undefined || eventTypes.length <= 0) {
        return <CircularProgress />;
    }
    return (
        <>
            <ShareJournalEntryPopUp
                channelManager={props.channelManager}
                leuteModel={props.leuteModel}
                topicModel={props.topicModel}
                sharedEntry={sharedEntry}
                isOpen={isSharePopupOpen}
                onClose={close}
            />
            <Journal
                journalModel={props.journalModel}
                eventTypes={eventTypes.map(event => ({...event, addonRenderFn}))}
                enableFiltering={false}
                leuteModel={props.leuteModel}
                enableSeparator={true}
            />
        </>
    );
}
