import FileCopyIcon from '@mui/icons-material/ContentCopyOutlined.js';
import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import './DisclaimerBody.css';

export function DisclaimerBody(): ReactElement {
    const i18n = useTranslation();
    return (
        <>
            <div className="disclaimer-welcome h1">
                {i18n.t('edda_onboarding.disclaimer.welcome')}
            </div>
            <div className="disclaimer-description body">
                {i18n.t('edda_onboarding.disclaimer.description.part1')}
            </div>
            <div className="disclaimer-description body">
                {i18n.t('edda_onboarding.disclaimer.description.part2')}
            </div>
            <div className="disclaimer-description body">
                {i18n.t('edda_onboarding.disclaimer.description.part3')}
            </div>
            <div className="disclaimer-description body">
                {i18n.t('edda_onboarding.disclaimer.description.part4_1')}
                <FileCopyIcon className="copy-icon" />
                {i18n.t('edda_onboarding.disclaimer.description.part4_2')}
            </div>
            <div className="disclaimer-description body">
                {i18n.t('edda_onboarding.disclaimer.description.part5')}
            </div>
        </>
    );
}
