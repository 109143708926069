import type {ReactElement} from 'react';
import {Route, Routes} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar';

import AppBarSettings from '@/components/appBar/AppBarSettings';
import PrivacyPolicy from './PrivacyPolicy';
import Imprint from './Imprint';

export default function AboutRouter(): ReactElement {
    const i18n = useTranslation();

    return (
        <Routes>
            <Route
                path="/imprint"
                element={
                    <>
                        <AppBarSettings
                            mode={APP_BAR_MODE.CHEVRON}
                            title={i18n.t('malawi_demo.imprintMenu')}
                            navigateBackOnClose={true}
                        />
                        <Imprint />
                    </>
                }
            />
            <Route
                path="/privacy-policy"
                element={
                    <>
                        <AppBarSettings
                            mode={APP_BAR_MODE.CHEVRON}
                            title={i18n.t('malawi_demo.privacyPolicyMenu')}
                            navigateBackOnClose={true}
                        />
                        <PrivacyPolicy />
                    </>
                }
            />
        </Routes>
    );
}
