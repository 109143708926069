import type {ReactElement} from 'react';
import {Route, Routes} from 'react-router-dom';

import type JournalModel from '@refinio/one.models/lib/models/JournalModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';

import PageNotFound from '@/components/PageNotFound.js';
import JournalView from './JournalView.js';

export default function JournalRouter(props: {
    channelManager: ChannelManager;
    questionnaireModel: QuestionnaireModel;
    journalModel: JournalModel;
    leuteModel: LeuteModel;
    topicModel: TopicModel;
}): ReactElement {
    return (
        <Routes>
            <Route
                path={'/'}
                element={
                    <JournalView
                        channelManager={props.channelManager}
                        journalModel={props.journalModel}
                        questionnaireModel={props.questionnaireModel}
                        leuteModel={props.leuteModel}
                        topicModel={props.topicModel}
                    />
                }
            />
            <Route element={<PageNotFound />} />
        </Routes>
    );
}
