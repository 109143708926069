import type {ReactElement} from 'react';

import Markdown from '@/components/markdown/Markdown';
import privacyPolicyMDFileUrl from '@/locales/edda/de/MDFiles/privacyPolicy.md';

import './About.css';

export default function PrivacyPolicy(): ReactElement {
    return (
        <div className="privacy-policy-container">
            <Markdown url={privacyPolicyMDFileUrl} className="about-md-container" />
        </div>
    );
}
