import type {ReactElement} from 'react';
import {getUA} from 'react-device-detect';

import Button from '@mui/material/Button/Button.js';

import i18n from '@/i18n.js';
import {Icon} from '@/components/icon/Icon.js';
import iPad_edda from '@/edda/resources/addToHomescreen/STH_iPad_Edda.svg';
import iPhone_edda from '@/edda/resources/addToHomescreen/STH_iPhone_Edda.svg';

import '@/components/addToHomeScreen/AddToHomeScreen.css';

/**
 * @returns {ReactElement}
 */
export default function AddToHomeScreen(): ReactElement {
    const regex = RegExp('iPad', 'i');
    const isiPad: boolean = regex.exec(getUA) !== null;

    return (
        <div className="add-to-home-screen-container">
            <div className="add-to-home-screen-header">
                <Icon name="Info" />
                <h2 className="headline margin-top">{i18n.t('addToHomeScreen.title')}</h2>
            </div>
            <div>
                {i18n.t('addToHomeScreen.paragraph1')}
                <span className="bold-text">{i18n.t('addToHomeScreen.paragraph1Bold')}</span>
            </div>
            <div className="italic-text">{i18n.t('addToHomeScreen.paragraph2')}</div>
            <div className="sth-steps-container">
                <div className="left-step-wrapper">
                    <div className="bold-text">{i18n.t('addToHomeScreen.step1')}</div>
                    <div className="step1-description">
                        {i18n.t('addToHomeScreen.step1DescriptionPart1')}
                        <img
                            src="/configs/edda/appicons/edda.svg"
                            alt="logo"
                            className="homescreen-icon"
                        />
                        <span>
                            {isiPad
                                ? i18n.t('addToHomeScreen.step1DescriptionPart2-iPad')
                                : i18n.t('addToHomeScreen.step1DescriptionPart2-iPhone')}
                        </span>
                    </div>
                </div>
                <div className="step-wrapper">
                    <div className="bold-text">{i18n.t('addToHomeScreen.step2')}</div>
                    <div>
                        {i18n.t('addToHomeScreen.step2DescriptionPart1')}
                        <span className="bold-text">
                            {i18n.t('addToHomeScreen.addToHomeScreen')}
                        </span>
                        <span>{i18n.t('addToHomeScreen.step2DescriptionPart2')}</span>
                        <span className="bold-text">{i18n.t('addToHomeScreen.add')}</span>
                        <span>{i18n.t('addToHomeScreen.step2DescriptionPart3')}</span>
                    </div>
                </div>
            </div>
            <div className="step3-title bold-text">{i18n.t('addToHomeScreen.step3')}</div>
            <div>{i18n.t('addToHomeScreen.step3Description')}</div>
            <img
                alt="logo"
                className={`${isiPad ? 'sth-image-ipad' : 'sth-image-iphone'}`}
                src={isiPad ? iPad_edda : iPhone_edda}
            />
            <div className="cnt-buttons-container">
                <Button
                    color="primary"
                    variant="contained"
                    disabled={false}
                    className="ad2hs-continue"
                    aria-label={i18n.t('addToHomeScreen.continued')}
                    onClick={() => {
                        localStorage.setItem('skipAddToHomeScreen', 'true');
                        window.location.reload();
                    }}
                >
                    {i18n.t('addToHomeScreen.continue')}
                </Button>
            </div>
        </div>
    );
}
