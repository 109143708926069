import type {ReactElement} from 'react';
import {useRef, useEffect, useContext} from 'react';

import AppBar, {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {useVHPhoneFixRef} from '@/hooks/device/window.js';
import Menu from '@/edda/components/menu/Menu.js';
import {MenuContext} from '@/context/MenuContext.js';

export default function PageTemplate(props: {
    children: ReactElement;
    className?: string;
}): ReactElement {
    const appBarRef = useRef<HTMLDivElement>(null);
    const appBarContext = useContext(AppBarContext);
    const {setMenuReference} = useContext(MenuContext);
    const container = useVHPhoneFixRef<HTMLDivElement>(appBarContext.contextValue.hide ? 0 : -50);

    useEffect(() => {
        if (container.current) {
            if (appBarContext.contextValue.hide) {
                container.current.style.maxHeight = 'inherit';
            } else {
                container.current.style.maxHeight = '';
            }
        }
    }, [container, appBarContext.contextValue.hide]);

    useEffect(() => {
        if (
            appBarRef.current &&
            appBarContext.contextValue.mode === 'view' &&
            !appBarContext.contextValue.hide
        ) {
            setMenuReference(appBarRef.current);
        }
    }, [
        appBarRef,
        appBarContext.contextValue.mode,
        setMenuReference,
        appBarContext.contextValue.hide
    ]);

    return (
        <>
            {!appBarContext.contextValue.hide && (
                <>
                    <Menu />
                    <div ref={appBarRef}>
                        <AppBar />
                    </div>
                </>
            )}
            <div
                className={`app-container${props.className ? ` ${props.className}` : ''}`}
                ref={container}
            >
                {props.children}
            </div>
        </>
    );
}
